<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称" v-if="isStoreUser">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="车辆编号">
          <a-input v-model="query.kartCode" placeholder="车辆编号"></a-input>
        </query-item>
        <query-item label="车辆名称">
          <a-input v-model="query.kartName" placeholder="车辆名称"></a-input>
        </query-item>
        <query-item label="状态">
          <a-select v-model="query.kartStatus" >
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
            <a-select-option :value="2">
              维护中
            </a-select-option>
          </a-select>
        </query-item>

      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { vehicleList } from '@/api/vehicle';
import queryMixin from '@/mixin/queryMixin';
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryCarData } from '@/api/manage';

export default {
  name: 'VehicleList',
  mixins: [queryMixin],
  components: {
  },
  props: {},
  data() {
    return {
      query: {
        shopId: null,
        kartCode: null,
        kartName: null,
        kartStatus: null,
        carCategoryId: null,
      },
      dictOptions: {},
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '车辆名称',
          dataIndex: 'kartName',
          key: 'kartName',
          ellipsis: true,
        },
        {
          title: '车辆编号',
          dataIndex: 'kartCode',
          key: 'kartCode',
          ellipsis: true,
        },
        {
          title: '车辆组别',
          dataIndex: 'carCategoryId',
          key: 'carCategoryId',
          ellipsis: true,
          customRender: (text) => (text ? filterMultiDictText(this.dictOptions.carCategoryId, text) : ''),
        },
        // {
        //   title: '燃料类型',
        //   dataIndex: 'fuelTypeName',
        //   key: 'fuelTypeName',
        //   ellipsis: true,
        // },
        // {
        //   title: '燃料剩余（%）',
        //   dataIndex: 'fuelSurplus',
        //   key: 'fuelSurplus',
        //   ellipsis: true,
        //   customRender: (text) => {
        //     return (
        //       <a-progress percent={text} size="small" />
        //     );
        //   }
        // },
        // {
        //   title: '续航里程（Km）',
        //   dataIndex: 'mileage',
        //   key: 'mileage',
        //   ellipsis: true,
        // },
        // {
        //   title: '累计里程（Km）',
        //   dataIndex: 'totalMileage',
        //   key: 'totalMileage',
        //   ellipsis: true,
        // },
        // {
        //   title: '状态',
        //   dataIndex: 'kartStatusName',
        //   key: 'kartStatusName',
        //   width: 120
        // },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children: <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleDetail(row) }>明细</a-button>
              </section>,
            };
          }
        },
      ],
      dataList: []
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    },
    shopId() {
      return this.$route.params.shopId;
    },
  },
  watch: {},
  created() {
    if (this.shopId) {
      this.query.shopId = this.shopId;
    }
    this.handleQuery();
    this.initDictConfig();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      vehicleList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.kartId;
        });
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'vehicleAdd'
      });
    },

    handleInfo(row) {
      this.$router.push({
        name: 'vehicleInfo',
        params: {
          id: row.kartId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'vehicleEdit',
        params: {
          id: row.kartId
        }
      });
    },
    handleDetail(row) {
      const { shopId } = this.query;
      this.$router.push({
        name: 'vehicleDetail',
        params: {
          kartId: row.kartId,
          kartName: row.kartName,
          shopId
        }
      });
    },
    initDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'carCategoryId', res);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
