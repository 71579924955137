import axios from '../libs/axios';

// 车辆列表
const vehicleList = (data) => {
  return axios.get('/api/trd/kart/list', data);
};
// 车辆费用
const vehicleDetail = (data) => {
  return axios.get('/api/trd/kart/detail', data);
};

// 车辆信息
const vehicleInfo = (id) => {
  return axios.get(`/api/trd/kart/info/${id}`);
};

// 车辆保存/修改
const vehicleSubmit = (data) => {
  return axios.post('/api/trd/kart/save', data);
};

// 车辆类型列表
const vehicleCategoryList = (data) => {
  return axios.get('/api/trd/kartclassify/list', data);
};

// 获取全部车辆列表
const vehicleAllCateGoryList = () => vehicleCategoryList({pageIndex: 1, pageSize: 999});

// 车辆类型信息
const vehicleCategoryInfo = (id) => {
  return axios.get(`/api/trd/kartclassify/info/${id}`);
};

// 车辆类型保存/修改
const vehicleCategorySubmit = (data) => {
  return axios.post('/api/trd/kartclassify/save', data);
};

// 车辆计费规则列表
const vehicleRuleList = (data) => {
  return axios.get('/api/trd/kartclassify/rule/list', data);
};

// 下单的分类列表
const vehicleQueryAll = (data) => {
  return axios.get('/api/trd/kartclassify/queryAll', data);
};
// 下单的分类列表
const vehicleQueryAllByCategoryId = (carCategoryId) => {
  return axios.get(`/api/trd/kartclassify/queryAllByCarCategoryId/${carCategoryId}`);
};

// 车辆计费规则信息
const vehicleRuleInfo = (id) => {
  return axios.get(`/api/trd/kartclassify/rule/info/${id}`);
};

// 车辆计费规则保存/修改
const vehicleRuleSubmit = (data) => {
  return axios.post('/api/trd/kartclassify/rule/save', data);
};

// // 小程序显示/隐藏
// const vehicleRuleMinShow = (data) => {
//   return axios.post('/api/trd/kartclassify/rule/minShow', data);
// };

const vehicleUseStatistics = (data) => {
  return axios.post('/api/trd/kart/useStatistics', data);
};
const orderTotalCount = (data) => {
  return axios.post('/api/trd/order/queryPlayQueueTotal', data);
};

// 车辆类型删除
const vehicleCategoryDelete = (kartClassifyId) => {
  return axios.get(`/api/trd/kartclassify/deleteById/${kartClassifyId}`);
};

// 车辆类型列表-菜单
const vehicleCategoryMenuList = (data) => {
  return axios.get('/api/trd/kartclassify/menu/list', data);
};

// 车辆类型列表-根据状态查询
const vehicleCategoryMenuListByStaus = (data) => {
  return axios.get('/api/trd/kartclassify/menu/listByStaus', data);
};

// 显示/隐藏控制开关
const vehicleRuleControlSwitch = (data) => {
  return axios.post('/api/trd/kartclassify/rule/controlSwitch', data);
};
export {
  vehicleList,
  vehicleDetail,
  vehicleInfo,
  vehicleSubmit,
  vehicleCategoryList,
  vehicleAllCateGoryList,
  vehicleCategoryInfo,
  vehicleCategorySubmit,
  vehicleRuleList,
  vehicleQueryAll,
  vehicleRuleInfo,
  vehicleRuleSubmit,
  // vehicleRuleMinShow,
  vehicleUseStatistics,
  vehicleCategoryDelete,
  vehicleCategoryMenuList,
  vehicleQueryAllByCategoryId,
  vehicleCategoryMenuListByStaus,
  vehicleRuleControlSwitch,
  orderTotalCount
};
